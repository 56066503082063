.btn {
  &:hover {
    color: #fff;
  }

  &:focus button:focus {
    outline-color: #fff;
    box-shadow: none;
  }

  &.btn-success {
    background: $success-color;
    border-color: $success-color;

    &:hover {
      background: $success-color;
      border-color: $success-color;
    }
  }

  &.btn-danger {
    background: red;
    border-color: red ;
  }

  &.btn-close {
    width: 174px;
    height: 38px;

    float: right;
    margin: 18px 0 0 17px;

    color: #fff;
    background-color: #03abce;
    border-color: #03abce;
    border-radius: 4px;
  }

  &.btn-float {
    position: fixed;
    width: 60px;
    height: 60px;

    bottom: 40px;
    right: 40px;

    color: #fff;
    text-align: center;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
  }

  &.btn-contained {
    border-color: $success-color !important;
    color: $success-color !important;
    background-color: $white-color !important;
    margin-left: 10px;
  }
}

.auxo-primary-btn {
  float: right;
  height: 38px;
  width: 174px;
  margin: 18px 0 0 17px;
  border-radius: 4px;
  background-color: #03abce;
  color: #fff;
}

.close-x {
  border: 1px solid #ddd !important;
  border-radius: 14px;
  padding: 3px 7px 3px 7px !important;

  &:focus {
    outline-color: #fff;
  }
}

.float-icon {
  margin-top: 6px;
  margin-left: 6px;
}

.round {
  label {
    position: absolute;

    height: 20px;
    width: 20px;

    left: 0;
    top: 0;

    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    &:after {
      position: absolute;
      height: 6px;
      width: 12px;
      left: 3px;
      top: 5px;

      transform: rotate(-45deg);
      content: "";
      opacity: 0;

      border: 2px solid #fff;
      border-top: none;
      border-right: none;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;

    &:checked + label {
      background-color: #29da8a;
      border-color: #29da8a;

      &:after {
        opacity: 1;
      }
    }
  }
}

.search-bar {
  height: 80px;
  justify-content: center;

  div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.dropdown-menu {
  top: 30px;
}

.modal-footer {
  padding: 0;
}

.error-modal {
  width: 36%;
}

.heading-container {
  position: relative;
  width: 100%;
  min-height: 1px;
  margin: 30px 0 30px 0;
  padding-right: 15px;
  padding-left: 15px;
}

.nav-tabs .nav-item {
  background: #f2f2f2;
  margin-right: 2px;
  border-radius: 2px;
  color: #8a96a0;
}

.table-auxo {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;

  thead {
    background-color: #f6f7f8;
    color: #727272;
    font-size: 11px;
    letter-spacing: 1px;
  }

  th {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    padding: 0.95rem;
    vertical-align: top;
    height: 42px;
  }

  td {
    padding: 0.95rem;
    height: 42px;
    color: #8a96a0;
    vertical-align: middle;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;
  }
}

.round {
  position: relative;
}

.no-data-found {
  text-align: center;
  display: block;
  color: #5e5e5e;
  width: 100%;

  button {
    height: 38px;
    width: 174px;
    border-radius: 4px;
    background-color: #03abce;
    color: #fff;
    margin: 18px 0 0 17px;
  }
}

.error-detail-link {
  cursor: pointer;
  color: #03abce !important;
  font-size: 12px;
  margin-top: 10px;
}

.network-error-details {
  border-top: 1px solid #ddd;
  padding-top: 23px;
}

.transform-arrow-click {
  transform: rotate(180deg);
}

.list-view-transform {
  transform: rotate(90deg);
}

.popup-close-btn {
  padding: 6px 16px 6px 0;

  button {
    border: 1px solid #ddd;
    border-radius: 14px;
    padding: 3px 7px 3px 7px;
  }
}

.heading-primary {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.12px;
}

.heading-primary-sm {
  @extend .heading-primary;
  font-size: 16px;
}

.heading-primary-up {
  @extend .heading-primary;
  text-transform: uppercase;
}

.badge-label {
  @extend .heading-primary;
  margin-left: 20px;
  font-weight: 300;
  color: $badge-color;
}

.badge-label-sm {
  @extend .badge-label;
  margin-left: 0;
  font-size: 16px;
}

.img-container {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-backdrop{
  height: 100% !important;
  width: 100% !important;
}