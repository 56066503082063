.bg-faded {
  background: $dark-color;
}

.blue-bg {
  background-color: $success-color;
  color: $white-color;
}

.black-text {
  color: $dark-grey-color;
}

.red-text {
  color: $red-color !important;
}

.bold-text {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}
