.checkbox-list-item {
  border: 1px solid #F1F1F1;
  height: 38px; }

.checkbox-inline {
  display: flex;
  flex-direction: row !important; }

.checkbox-list-in-item {
  flex: none;
  list-style: none; }
