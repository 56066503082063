.sidebar {
  position: fixed;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0 !important;
  margin-right: -15px;
  margin-left: -15px;
  min-height: 100%;
  height: 100%; }
  .sidebar .sidebar-header {
    padding: 20px 15px 20px;
    text-align: center;
    display: block; }
  .sidebar .nav-divider {
    height: 0;
    overflow: hidden;
    border-top: 2px solid #424A4C;
    margin: 0.5rem 15px; }
  .sidebar .nav {
    margin-bottom: 20px;
    margin-top: 20px; }
  .sidebar .nav-item {
    transition: max-height 0.2s ease-out;
    width: 100%;
    vertical-align: middle;
    font-size: 16px; }
    .sidebar .nav-item a {
      color: white;
      height: 100%; }
    .sidebar .nav-item .nav-item-box {
      width: 45px;
      color: #fff; }
      .sidebar .nav-item .nav-item-box .nav-item-icon {
        padding: 10px 15px 15px 0;
        width: 37px; }
      .sidebar .nav-item .nav-item-box .nav-item-icon-trip {
        padding: 10px 8px 15px 0;
        width: 37px; }
    .sidebar .nav-item:hover .nav {
      display: block; }
    .sidebar .nav-item:active .nav {
      display: block !important; }
  .sidebar .nav-item + .nav-item {
    margin-left: 0; }
  .sidebar .nav-pills {
    transition: max-height 0.2s ease-out; }
    .sidebar .nav-pills .nav-link,
    .sidebar .nav-pills .nav-pills .show > .nav-link {
      border-radius: 0; }
    .sidebar .nav-pills .nav-link.active,
    .sidebar .nav-pills .nav-pills .show > .nav-link {
      border-radius: 0;
      background-color: #181B1C;
      display: block !important; }
    .sidebar .nav-pills .nav-item.active .nav {
      display: block !important; }

.bat-special {
  display: flex;
  align-items: center;
  width: 100% !important; }

.andr {
  height: 60px; }

.align-andr {
  position: relative;
  top: 10px; }

.margin-left-andr {
  margin-left: 18px; }

.collapse-nav > .nav {
  padding-left: 20px;
  margin-top: 0; }

.collapse-nav > .nav > .nav-item {
  border-left: 3px solid #03ABCE; }

.shrinked {
  width: 95px; }
  .shrinked .nav-text {
    display: none; }

.logout-link {
  bottom: 10px; }

.nav-item:active .nav {
  display: block !important; }

.header-icon-auxo {
  width: 158px;
  cursor: pointer; }
