.textbold {
  font-weight: bold;
  margin-left: 10px;
  position: relative;
  top: 2px;
  color: #242A2B;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px; }

.section-list-icons {
  width: 14px; }

.simple-txt {
  font-weight: bold;
  color: #8A96A0;
  margin-left: 0.6em;
  font-size: 0.75em;
  display: inline-block; }
  .simple-txt span {
    font-weight: 400;
    margin-left: 4px; }

.section-icons {
  display: inline-block;
  width: 20px; }

.section-side {
  border-top: 1px solid #f5f5f5;
  padding: 8px 0 8px 13px; }
  .section-side .vehicle-info {
    margin-bottom: 3px; }

.cluster-map-view {
  padding: 0; }

.fa-circle {
  color: #29DA8A;
  margin-right: 5px; }

.circle-orange {
  color: #FFA500 !important; }

.circle-gray {
  color: #828282 !important; }

.circle-blue {
  color: #03ABCE !important; }

.circle-green {
  color: #0abf60 !important; }

.circle-red {
  color: #FF0000 !important; }

.pin-icon-list {
  width: 28px;
  margin-top: 3px; }

.aa {
  position: relative;
  right: 20px;
  top: 5px; }

.top-row {
  box-sizing: border-box;
  height: 80px;
  background-color: #FFFFFF; }

.margin-top-20 {
  margin-top: 20px; }

.no-wrap {
  white-space: nowrap;
  position: relative;
  right: 11px; }

.things-listing-with-maps {
  height: 71vh;
  overflow-y: scroll;
  padding-left: 0; }
  .things-listing-with-maps .section-side:hover,
  .things-listing-with-maps .section-side.selected {
    background-color: #F6F7F8; }

.search-icon {
  position: absolute;
  top: 6px;
  right: 26px; }

.counter {
  color: #03ABCE;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.07px;
  line-height: 47px;
  text-align: center; }

.red-circle {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  border: 2px solid #D0021B; }

.card-heading {
  color: #232A2B;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.64px;
  line-height: 25px; }

.card-data {
  color: #9B9B9B;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.62px;
  line-height: 16px; }

.grey-out {
  filter: grayscale(100%); }

.map-shadow {
  background: #e0e0e0;
  box-shadow: 5px 5px 37px #bcbcbc, -5px -5px 37px #ffffff; }

.card {
  min-width: 343px;
  padding: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  margin: 10px; }

.font-15 {
  white-space: nowrap;
  font-size: 12px; }

.margin-top-12 {
  margin-top: 12px; }

.margin-top-25 {
  margin-top: 25px; }

.slick-arrow::before {
  color: #000; }

.card-display {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 80%; }

.hide-card {
  display: none !important; }

.height32 {
  height: 32px; }

.inline-block {
  display: inline-block !important; }

.font-33 {
  font-size: 33px !important; }

.treeDropdown {
  width: 100%; }
  .treeDropdown .dropdown {
    width: 100%; }
    .treeDropdown .dropdown .dropdown-trigger {
      align-items: center;
      background-color: white;
      border-color: #cccccc;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      line-height: 12px;
      width: 100%; }
      .treeDropdown .dropdown .dropdown-trigger .tag-list .tag-item .search {
        color: gray;
        border-bottom: none; }
    .treeDropdown .dropdown .dropdown-content {
      width: 100%; }

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  margin: 4px 7px;
  float: right;
  content: url("./svgContent.svg");
  border-left: 1px solid #cccccc;
  padding-left: 9px; }

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  margin: 4px 7px;
  float: right;
  content: url("./svgContent.svg");
  border-left: 1px solid #cccccc;
  padding-left: 9px; }

.node-label {
  color: gray;
  font-size: 12px; }

.node .fa-ban {
  color: gray;
  font-size: 12px; }

.SharedURL {
  display: flex;
  justify-content: space-between; }

.SharedURL .textField {
  width: 80%; }

.SharedURL .copyBtn {
  width: 20%;
  text-align: center; }

.SharedURL .copyBtn button {
  margin-top: 25px; }
