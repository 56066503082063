.create-role {
  width: 100%; }
  .create-role th {
    font-size: 15px;
    height: 50px; }
    .create-role th td {
      border: 1px solid #dee2e6;
      padding-left: 17px; }

.add-role {
  height: 70px; }
