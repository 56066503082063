$black-color: #000000;
$white-color: #ffffff;
$red-color: #cc1238;

$input-placeholder-color: #b9bbbd;
$badge-color: #9b9b9b;
$light-grey-color: #727272;
$grey-color: #8a96a0;
$dark-grey-color: #555555;
$darker-grey-color :#444141;
$dark-color: #242a2b;

$success-color: #03abce;
$success-dark-color: #4f6ebd;

$lightest-silver-color: #f1f1f1;
$lighter-silver-color: #f6f6f6;
$light-silver-color: #dadada;
$silver-color: #c0c0c0;

$light-grey: #d3d3d3;
$light-material-white-color: #f6f7f8;
$material-white-color: #dddddd;

