.reports-header {
    height: 70px;
    display: flex;
    flex-direction: row;
    span.headerchip {
        margin: auto 0;
        padding-right: 20px;
        color: #DADADA;
        font-size: 26px;
        font-weight: 300;
        letter-spacing: 0.13px;
    }
    span.export {
        font-weight: bold !important;
        color: #000;
    }
}

.reports-list {
    list-style: none;
    padding-left: 0;
    .interval-report {
        .interval-icon {
            flex-grow: 0 !important;
        }
        span {
            margin: auto 0 !important;
            a {
                margin-left: 40px;
            }
        }
    }
    li {
        display: flex;
        height: 70px;
        border-bottom: 1px solid #F1F1F1;
        span {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin: auto;
            h4 {
                color: #242A2B;
                font-family: Roboto;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 0;
                margin: auto 0;
            }
            p {
                color: #8A96A0;
                font-family: Roboto;
                font-size: 14px;
                letter-spacing: 0.1px;
                margin: auto 0;
            }
        }
        .select-report {
            align-items: flex-end;
            a {
                margin: auto 35px;
                color: #03ABCE;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.1px;
            }
            a:hover {
                color: #03ABCE;
            }
        }
    }
}

.PreviewArea {
    box-shadow: 1px 1px 81px rgba(41, 60, 74, 0.18);
}

.popover-body {
    padding: 0;
}

.selected-color {
    color: #444141 !important;
}

.columns-to-select {
    height: auto !important;
    overflow-y: hidden !important;
}

.selected-Interval {
    margin-top: 35px;
    p {
        font-size: 16px;
    }
}

.black-white-button {
    :first-child {
        color: #444141 !important;
       
    }
}