.m-0 { margin: 0 !important; }

.m-b-0 { margin-bottom: 0 !important; }
.m-b-9-px { margin-bottom: 9px !important; }
.m-b-10-px { margin-bottom: 10px !important; }
.m-b-20-px { margin-bottom: 20px !important; }
.m-b-50-px { margin-bottom: 50px !important; }

.m-t-10-px { margin-top: 10px !important; }
.m-t-20-px { margin-top: 20px !important; }
.m-t-43-px { margin-top: 43px !important; }
.m-t-43-p { margin-top: 43% !important; }
.m-t-40-p { margin-top: 40px !important; }
.m-t-0-px { margin-top: 0px !important; }
.m-t-135-px { margin-top: 135px !important; }
.m-t-m65 { margin-top: -65px !important; }

.m-l-5-px { margin-left: 5px !important; }
.m-l-20-px { margin-left: 20px !important; }
.m-t-100-px { margin-top: 100px !important; }

.m-r-10-px { margin-right: 10px !important; }
.m-btn-v{
  margin: -3px 0px -2px 0px !important;
    height: 26px !important;
    width: 37px !important;
    line-height: 1px;
}
