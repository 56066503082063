.login-container {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
  background: url("../../assets/img/login-background.jpg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  font-size: 13px;
  overflow-x: hidden; }

.login-box {
  width: 370px;
  height: 100%;
  padding: 30px;
  background: white;
  border-radius: 4px; }
  .login-box .login-logo {
    margin: auto; }
  .login-box .btn-login {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold; }

.login-form {
  margin-top: 40px; }
  .login-form .help-link-section {
    margin-top: 30px; }
    .login-form .help-link-section a {
      color: #8A96A0; }
