/* card.scss */
.mt-0 {
  margin-top: 0 !important; }

.card {
  width: 100% !important;
  height: 250px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding-top: 0 !important;
  margin: 5px;
  border: 2px solid #03abce;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .card .card-header {
    background-color: #03abce;
    color: #fff;
    padding: 10px;
    font-size: 16px !important;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #fff;
    width: 100%; }
    .card .card-header .card-header-title {
      font-size: 18px !important; }
  .card .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 40px 20px 32px;
    font-size: 24px;
    height: 100px;
    width: 100%;
    font-weight: 700;
    margin: auto;
    color: #333;
    font-family: Roboto, sans-serif; }
    .card .card-body p {
      font-size: 30px; }
  .card .card-img {
    width: 70px;
    height: 70px;
    object-fit: cover; }

.h-95 {
  height: 98% !important; }

.h-45 {
  height: 67% !important; }

.h-90 {
  height: 95% !important; }

.h-70vh {
  height: 590px !important; }

.css-6q0nyr-Svg {
  display: none !important; }

.change-stepper-container {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  border-radius: 8px; }

.left-panel {
  display: flex;
  flex: 1 1; }

.right-panel {
  display: flex;
  align-items: center; }

/* playback.scss */
.change-stepper-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 8px; }

.left-panel {
  display: flex;
  flex: 1; }

.right-panel {
  display: flex;
  align-items: center; }

/* summary.scss */
.h-95 {
  height: 98% !important; }

.css-6q0nyr-Svg {
  display: none !important; }

.trikes-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px; }

.trikes-parent-row {
  display: grid;
  grid-template-columns: 75% 25%;
  gap: 10px;
  margin-top: 30px; }
  .trikes-parent-row .trikes-item {
    display: flex;
    justify-content: space-between; }

.unique-select-wrapper .group-heading {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.7; }

.unique-select-wrapper .option--is-enabled {
  cursor: pointer !important;
  padding-left: 30px !important; }

.pre-line {
  white-space: pre-line; }
