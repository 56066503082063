.content-whole {
  min-height: 100%; }

.modalParent {
  width: 300px; }

.fixed-top {
  margin-left: 15.66667%; }

.navbar {
  height: 55px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08); }

.header {
  margin: -15px -15px 15px -30px; }

.group-header-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 22px; }

.header-group-name {
  height: 16px;
  color: #242A2B;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.62px;
  line-height: 0; }

.dropdown .nav-item {
  list-style: none; }

.notification-bell {
  padding: 0px 42px; }
  .notification-bell span {
    border: 1px solid #000;
    border-radius: 15px;
    padding: 4px; }

.notification-area {
  height: 77px;
  width: 382px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid #ddd;
  display: flex; }
  .notification-area span {
    margin: auto;
    font-size: 17px;
    font-weight: 500; }
