.info-bubble {
  background-color: #000;
  max-width: 202px; }
  .info-bubble span {
    color: #fff;
    font-size: 11px;
    font-weight: 500; }

.gm-style .gm-style-iw > button {
  display: none;
  /* <-- this will generally work on the fly. */
  visibility: hidden;
  /* this 2 lines below are just for hard hiding. :) */
  opacity: 0;
  text-align: center;
  background-color: #000 !important; }

.gm-style .gm-style-iw .gm-style-iw-d {
  background-color: #000 !important;
  overflow: hidden !important;
  margin: 0px 14px 14px 0px; }

.gm-style .gm-style-iw-t::after {
  border-top: 11px solid #000; }

.gm-style-iw .gm-style-iw-c {
  background-color: #000 !important; }

.gm-style .gm-style-iw-c {
  background-color: #000 !important; }

.gm-svpc {
  display: none; }

.gm-style .gm-style-iw-t::after {
  background: none !important;
  box-shadow: none !important; }
