.geofence-map-container {
  width: 100%;
  border-radius: 5px;
  height: 600px; }

.geofence-map-element {
  height: 100%;
  width: 100%;
  border-radius: 5px; }

.geofence-card {
  padding: 24px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08); }
